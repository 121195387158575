<template>
  <div>        
        <h1 class="text-slate-200 text-4xl font-bold tracking-tight  sm:text-5xl">
            Logan Foreman
        </h1>
        <h2 class="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">
            Software Developer
        </h2>
        <p class="mt-4 max-w-xs leading-normal">
            I create practical yet creative engineering solutions for businesses.
        </p>
        
        <nav class="nav hidden lg:block" aria-label="In-page jump links">
            
            <ul class="mt-16 w-max">
        <!-- Dynamically set active class based on activeSection -->
        <li v-for="section in sections" :key="section.id">
          <a @click="$emit('scroll-to', section.id)" :href="`#${section.id}`" class="btn group flex items-center py-3" :class="{ 'active': activeSection === section.id }">
            <svg width="277" height="62">
              <defs>
                <radialGradient id="topStartGradient" cx="50%" cy="80%" r="80%">
                  <stop offset="0%" stop-color="#134e4a"/>
                  <stop offset="50%" stop-color="#0f766e"/>
                  <stop offset="100%" stop-color="#a5f3fc"/>
                </radialGradient>
              </defs>
              <rect x="5" y="5" rx="25" fill="none" stroke="url(#topStartGradient)" width="266" height="50"></rect>
            </svg>
            <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
            <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">{{ section.name }}</span>
          </a>
        </li>
      </ul>
        </nav>
    </div>
    <ul class=" ml-1 mt-8 flex items-center" aria-label="Social media">
        <li class="mr-5 text-xs shrink-0">
            <a class="block hover:text-slate-200" href="https://github.com/thankslogan" target="_blank" rel="noreferrer noopener" aria-label="GitHub (opens in a new tab)" title="GitHub">
                <span class="sr-only">GitHub</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-6 w-6" aria-hidden="true">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z">
                    </path>
                </svg>
            </a>
        </li>
        <li class="mr-5 text-xs shrink-0">
            <a class="block hover:text-slate-200" href="https://www.linkedin.com/in/logan-foreman-cs/" target="_blank" rel="noreferrer noopener" aria-label="LinkedIn (opens in a new tab)" title="LinkedIn">
                <span class="sr-only">LinkedIn</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6" aria-hidden="true">
                    <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z">
                    </path>
                </svg>
            </a>
        </li>
            
    </ul>
    
  </template>
  
  <script>
  export default {
    props: ['activeSection'], // Expect an activeSection prop
    data() {
      return {
        sections: [
          { id: 'about', name: 'About' },
          { id: 'projects', name: 'Projects' },
          { id: 'experience', name: 'Experience' },
        ],
      };
    },
  };
  </script>

  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
  
  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons vertically */
  }
  
  .nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .btn {
    margin-top: 20px; /* Adjust space between buttons */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Adjust width as necessary */
    max-width: 277px; /* Keep max-width for larger screens */
    height: 50px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 16px; /* Adjust font size */
    text-transform: uppercase;
    letter-spacing: 0.045em;
  }
  
  .btn svg {
    width: 100%;
    max-width: 277px; /* Ensure SVG doesn't exceed this width */
    height: 62px; /* Adjust height as necessary */
  }
  
  .btn svg rect {
    stroke-width: 2;
    stroke-dasharray: 196, 543;
    stroke-dashoffset: 437;
    transition: all 600ms ease;
  }
  
  .btn span {
    position: absolute;
    z-index: 1;
    background-clip: text;
  }
  
  .btn:hover svg rect {
    stroke-dasharray: 353, 0;
    stroke-dashoffset: 0;
  }
  .nav-text {
  transition: color 0.3s ease;
  color: #718096; /* Equivalent to Tailwind's text-slate-500 */
    }

  .btn:hover .nav-text {
  color: #f7fafc; /* Equivalent to Tailwind's text-slate-200 */
    }
  .btn:hover .nav-text {
  color: #f7fafc; /* Equivalent to Tailwind's text-slate-200 */
    }
    .btn.active svg rect, .btn:hover svg rect {
    stroke-dasharray: 353, 0;
    stroke-dashoffset: 0;
}

.btn.active .nav-text, .btn:hover .nav-text {
    color: #f7fafc; /* Equivalent to Tailwind's text-slate-200 */
}
  </style>
  
  
  
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

<template>
  <html lang="en" class="scroll-smooth">
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet">
  </head>
  <body class="app-background leading-relaxed text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900">    

    <div class="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-inter-light font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
      <div class="lg:flex lg:justify-between lg:gap-4">
        <header class="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
          <StaticSidebar @scroll-to="handleScroll" />
        </header>
        <main class="pt-24 lg:w-1/2 lg:py-24"> 
          <ScrollableContent ref="scrollableContent" />
        </main>
      </div>
    </div>
  </body>
  </html>
  
</template>

<script>
import ScrollableContent from './components/ScrollableContent.vue'
import StaticSidebar from './components/StaticSidebar.vue'

export default {
  name: 'App',
  components: {
    ScrollableContent,
    StaticSidebar
  },
  methods: {
    handleScroll(sectionId) {
      this.activeSection = sectionId; //update the active section based on whats visible in scroll
      this.$refs.scrollableContent.scrollToSection(sectionId);
    }
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.activeSection = entry.target.id;
      }
    });
  }, {rootMargin: '-50% 0px -50% 0px'});

  document.querySelectorAll('section').forEach((section) => {
    observer.observe(section);
  });
}
};
</script>


<style>

html, body {
  scroll-behavior: smooth;
  background-color: #000; /* Sets the background to black */
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.app-background {
  animation: gradientBackground 5s ease infinite;
  background-size: 200% 200%;
  background-image: 
    linear-gradient(
      60deg,
      #022c55 0%, /* Dark Blue */
      #003131 50%, /* Dark Green */
      #2a1341 100% /* Dark Purple */
    ),
    url("data:image/svg+xml,%3Csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)'/%3E%3C/svg%3E");
  width: 100%;
  min-height: 100vh;
}
</style>


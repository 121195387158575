
<template>
    <section id="about" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" ref="content" aria-label="About me">
        <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/50 px-6 py-5 backdrop-blur-80 md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
            <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">About
        </h2>
    </div>
            
        <p>
            <b>Hello.</b>
            <br>
            I am a recent bachelor's graduate of computer science with an 
            interest in full-stack application development, blockchain technology, 
            and embedded programming. 
            I pride myself on being a determined learner, and eager to grow my technical skillset. 
            My proficiency lies in <a class="font-extrabold">C++</a>,<a class="font-extrabold"> Python</a>, and <a class="font-extrabold">Java</a> achieved 
            through years of education at the university level and numerous <b> projects.</b> 
            <br>
            <br>
            My most recent endeavours since my December 2023 graduation have been embarking on a 
            web-development journey through advanced web frameworks, studying 
            both <a class="font-extrabold"> front-end </a> and <a class="font-extrabold">back-end </a> solutions.
        
            Namely, <a class="font-extrabold">React</a> 
            & 
            <a class="font-extrabold">
                Vue </a>
                ,<a class="font-extrabold">
                    Django</a> 
                    , cloud computing services such as 
                    <a class="font-extrabold">
                        AWS-EC2
                    </a>
            , and containerization platforms like <a class="font-extrabold">Docker</a>.
           </p> 
           <br>
        
            <p>
                When I'm not locked in to my computer, I enjoy surfing, 
                beach volleyball, cooking, dogs, and live music 👻
            </p>
    </section>
    <section id="projects" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" ref="content" aria-label="My Projects">
        <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/50 px-6 py-5 backdrop-blur-80 md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
            <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Projects
        </h2>
        </div>
        <div>
            <ul class="group/list">
                <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                        <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                        </div>
                        <div class="z-10 sm:order-2 sm:col-span-6">
                            <h3>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://github.com/ThanksLogan/RentalInventoryManager" target="_blank" rel="noreferrer noopener" aria-label="Rental Inventory Manager (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block">

                                    </span>
                                    <span>Rental Inventory Manager<!-- --> 
                                        <span class="inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm leading-normal">
                                A comprehensive GUI application designed to streamline the management of rental inventory, 
                                particularly for furniture and event supplies. This tool offers an intuitive user interface for tracking inventory items, 
                                managing bookings, and organizing rental packages. The management tools can prevent common inventory management mishaps like 
                                overbooking items for a particular day, and displaying the correct number of items available for rent.

                            </p>
                            <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">
                                        QT
</div></li>
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">
                                        Python

                                    </div></li>
                                    <li class="mr-1.5 mt-2">
                                        <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">
                                            SQL

                                    </div></li>
                        </ul>
                        </div>
                        <img alt="Rental Inventory Manager Preview Card" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" 
                        style="color:transparent" 
                        src="../assets/RIMS.png">
                    </div>
                </li>
                <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                        <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                        </div>
                        <div class="z-10 sm:order-2 sm:col-span-6">
                            <h3>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://github.com/ThanksLogan/SurfTrak" target="_blank" rel="noreferrer noopener" aria-label="Surf Trak (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block">

                                    </span>
                                    <span>SurfTrak<!-- --> 
                                        <span class="inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm leading-normal">
                                A surf report display panel serving updated surf and weather conditions. Allows users
                                to know the surf report with moderate accuracy in order to assist in trip planning. 
                                In a python script, I utilize the serial.tools module to communicate with the Arduino software. 
                                This is done by sharing the USB port as a means of communication, then sending data v
                                ia a Serial.write command. The python script itself consists of a web scraper 
                                I designed which utilizes the “BeautifulSoup” library and “re” module to fetch the 
                                HTML source code of the buoy website I needed. Another function used that scraped data 
                                to form a wave height prediction using a formula found from Surfline.com All of this data 
                                is written into a string where it can be easily transmitted to the Arduino's source code
                                which runs on the Arduino IDE
                            </p>
                            <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Arduino
</div></li>
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Python

                                    </div></li>
                                    <li class="mr-1.5 mt-2">
                                        <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">HTML

                                    </div></li>
                        </ul>
                        </div>
                        <img alt="SurfTrak Card" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" 
                        style="color:transparent" 
                        src="../assets/surftrak.png">
                    </div>
                </li>
                <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                        <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                        </div>
                        <div class="z-10 sm:order-2 sm:col-span-6">
                            <h3>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://github.com/ThanksLogan/Auto-Complete-Python-Bot-For-Homework-Site" target="_blank" rel="noreferrer noopener" aria-label="Rental Inventory Manager (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block">

                                    </span>
                                    <span>Python Selenium Bot for Homework Automation<!-- --> 
                                        <span class="inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm leading-normal">
                                This project is a fun side experiment to explore if a bot can automate the process 
                                of filling out simple homework website problems. It uses Python with the Selenium 
                                package to interact with web elements, simulate user actions, and perform tasks like 
                                filling forms and navigating through sections.
                            </p>
                            <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Python
                            </div></li>
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Selenium

                                    </div></li>
                        </ul>
                        </div>
                        <img alt="python homework bot Preview Card" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" 
                        style="color:transparent" 
                        src="../assets/pybot.png">
                    </div>
                </li>
                <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                        <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                        </div>
                        <div class="z-10 sm:order-2 sm:col-span-6">
                            <h3>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://github.com/ThanksLogan/Memory-Management-Unit-with-TLB_OperatingSystems" target="_blank" rel="noreferrer noopener" aria-label="MMU with TLB (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block">

                                    </span>
                                    <span>OS Memory Management Unit with TLB<!-- --> 
                                        <span class="inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm leading-normal">
                                This C++ project simulates a Memory Management Unit (MMU) 
                                with a Translation Lookaside Buffer (TLB). It processes virtual 
                                memory addresses from a trace file, translating them into physical
                                 addresses using a page table and TLB cache mechanism. The project 
                                 demonstrates concepts like virtual-to-physical address translation, 
                                 TLB caching, page table lookups, and LRU (Least Recently Used) cache 
                                 replacement policy.
                            </p>
                            <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">C++
                            </div></li>
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">Linux OS
                                    </div></li>
                        </ul>
                        </div>
                        <img alt="MMU with TLB Preview Card" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" 
                        style="color:transparent" 
                        src="../assets/mmu.png">
                    </div>
                </li>
                <li class="mb-12">
                    <div class="group relative grid gap-4 pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                        <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">
                        </div>
                        <div class="z-10 sm:order-2 sm:col-span-6">
                            <h3>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://github.com/ThanksLogan/Multithreaded-Crypto-Exchange-Simulator" target="_blank" rel="noreferrer noopener" aria-label="Rental Inventory Manager (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block">

                                    </span>
                                    <span>Crypto Exchange Simulation (Real-time with Multithreading)<!-- --> 
                                        <span class="inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-2 text-sm leading-normal">
                                This C++ project simulates a crypto exchange using multithreading, 
                                semaphores, and mutexes. It includes producers for Bitcoin and 
                                Ethereum trade requests, and consumers using different blockchains 
                                (X and Y) to process these requests.
                            </p>
                            <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                                <li class="mr-1.5 mt-2">
                                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300 ">C++
                            </div></li>
           
                        </ul>
                        </div>
                        <img alt="crypto exchange simulator Preview Card" loading="lazy" width="200" height="48" decoding="async" data-nimg="1" class="rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1" 
                        style="color:transparent" 
                        src="../assets/crypto.png">
                    </div>
                </li>
            </ul>
        </div>

    </section>
    <section id="experience" class="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24" ref="content" aria-label="Experience">
        <div class="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
            <h2 class="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">Experience
        </h2>
        </div>
        <div>
            <ol class="group/list">
                <li class="mb-12">
        <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                    <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">

                    </div>
                    <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to 2024">2018 — 2024

                    </header>
                    <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">
                            <div>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://eventlounge.co" target="_blank" rel="noreferrer noopener" aria-label="manager at event lounge co (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                                    <span>Manager - <!-- --> 
                                        <span class="inline-block">Event Lounge Co.
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </div>

                            <div>
                                <div class="text-slate-500" aria-hidden="true">Head of Event Operations

                                </div>
                            </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                            Coordinated with corporate event 
                            planners and venue managers for event furniture rentals. Contributed significantly 
                            to financial strategy, managed a staff of 6 operators, and assessed day-of-event logistics.
                            </p>
                            </div>
                        </div>
                </li>
                <li class="mb-12">
        <div class="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
                    <div class="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg">

                    </div>
                    <header class="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to 2024">Aug 2022 — DEC 2022

                    </header>
                    <div class="z-10 sm:col-span-6">
                        <h3 class="font-medium leading-snug text-slate-200">
                            <div>
                                <a class="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-teal-300 focus-visible:text-teal-300  group/link text-base" href="https://digitalinnovation.sdsu.edu" target="_blank" rel="noreferrer noopener" aria-label="researcher at sdsu dilab (opens in a new tab)">
                                    <span class="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span>
                                    <span><!-- --> 
                                        <span class="inline-block">SDSU Digital Innovation Lab
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd">

                                                </path>
                                            </svg>
                                        </span>
                                    </span>
                                </a>
                            </div>

                            <div>
                                <div class="text-slate-500" aria-hidden="true">Cybersecurity Researcher

                                </div>
                            </div>
                        </h3>
                        <p class="mt-2 text-sm leading-normal">
                            Researched information relating to IPv6 standards such as RFC, 
                            routing codes, and documents from government and 
                            Internet service providers to develop a thorough understanding of internet protocols.
                            Performing IPv6 traceroute, which discovered the route from sender to the target IP
                             address. 
                             Exploring cyber threats associated with IP addresses utilizing an IPV6-enabled cloud, 
                             advancing a knowledge base.
                            </p>
                            </div>
                        </div>
                </li>
            </ol>
        </div>
        
            
                    
    </section>
    <footer class="max-w-md pb-16 text-sm text-slate-500 sm:pb-0">
        <p>Stay tuned! 
            <br>
            <br>
            Currently working on projects to add.

            <br>
            <br>
            Project template inspired by Britanny Chiang, go check her out. Coded in Visual Studio by myself. Built with 
            Vue.js and TailwindCSS, deployed with Vercel.
            
        </p>
    </footer>

</template>

<script>
export default {
    methods: {
        scrollToSection(sectionId) {
            const section = this.$refs.content.querySelector(`#${sectionId}`);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth'});
            }
        }
    }
}
</script>

<style>
</style>